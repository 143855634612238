import React from "react"

import Layout from "../../components/Layout"
import Grid from "../../components/Grid"
import { Link } from "gatsby"
import css from "../setup.module.scss"
import fontType from "../../helpers/fontType.module.scss"

import TRACKINGCODE_EMAIL from "../../assets/images/setup/setup-trackcode-email.png"
import SETUP_3DCART_MENU from "../../assets/images/setup/setup-3dcart-menu.png"
import SETUP_3DCART_EDIT from "../../assets/images/setup/setup-3dcart-edit.png"
import SETUP_3DCART_PASTE from "../../assets/images/setup/setup-3dcart-paste.png"
import SETUP_3DCART_EMAILSMENU from "../../assets/images/setup/setup-3dcart-emailsmenu.png"
import SETUP_3DCART_EMAILSEDIT from "../../assets/images/setup/setup-3dcart-emailsedit.png"
import SETUP_3DCART_BCC from "../../assets/images/setup/setup-3dcart-bcc.png"

const metadata = {
  title: "Connect 3dcart to ReferralCandy",
}

const ThreedcartSetup = (props) => {
  return (
    <Layout metadata={metadata} pathname={props.location.pathname}>
      <Grid className={css.container} centeredClassName={css.centered}>
        <h1 className={css.center}>Set Up ReferralCandy for 3dcart</h1>
        <p className={fontType.b1}>
          Step 1: Setting up your ReferralCandy Account
        </p>
        <ol type="1">
          <li>
            Head over to the{" "}
            <a href="https://my.referralcandy.com/signup#3dcart">
              sign up page
            </a>{" "}
            to register for a new ReferralCandy account.
          </li>
          <li>
            Go through the account setup wizard. At the integration step, note
            the tracking code and email address for your referral program. You
            will need them for the next two parts.
          </li>
          <br />
          <br />
          <img src={TRACKINGCODE_EMAIL} alt="Tracking Code Email" />
          <li>Complete the rest of the setup wizard.</li>
        </ol>
        <p className={fontType.b1}>
          Step 2: Adding the tracking code to your 3dcart store
        </p>
        <ol type="1">
          <li>Log into your 3dcart Store Manager.</li>
          <li>
            In the Store Manager, in the left side bar, select "Settings" &gt;
            "Design" &gt; "Themes & Styles". There, select the "More" option,
            and click on "Edit Template (HTML)".
          </li>
          <br />
          <br />
          <img src={SETUP_3DCART_MENU} alt="Setup 3dcart menu" />
          <li>
            In the Template Editor, find the "checkout-step4.html" row, and
            click the "Edit" button on the right.
          </li>
          <br />
          <br />
          <img src={SETUP_3DCART_EDIT} alt="Setup 3dcart edit" />
          <li>
            Within "Edit Template", copy and paste the ReferralCandy tracking
            code from Step 2 into the bottom of the input box.
          </li>
          <li>
            Click the "Save" button on the top right of the page to save the
            changes.
          </li>
          <br />
          <br />
          <img src={SETUP_3DCART_PASTE} alt="Setup 3dcart paste" />
        </ol>
        <p className={fontType.b1}>
          Step 3: Sending invoice emails to ReferralCandy
        </p>
        <ol type="1">
          <li>
            In the Store Manager, in the left side bar, select "Settings" &gt;
            "Design" &gt; "Emails".
          </li>
          <br />
          <br />
          <img src={SETUP_3DCART_EMAILSMENU} alt="Setup 3dcart emails menu" />
          <li>
            In the "Emails" list, find the "New Order - Merchant" row within the
            "Order Emails" section, and click the "Edit" button on the right.
          </li>
          <br />
          <br />
          <img src={SETUP_3DCART_EMAILSEDIT} alt="Setup 3dcart emails edit" />
          <li>
            At the "New Order - Merchant" page, copy and paste the ReferralCandy
            email address from Step 2 into the "BCC to:" input box.
          </li>
          <li>
            Click the "Save" button in the top right corner to save the changes.
          </li>
          <br />
          <br />
          <img src={SETUP_3DCART_BCC} alt="Setup 3dcart BCC" />
        </ol>
        <h3>
          Using a different platform? <Link to="/setup">View all guides</Link>
        </h3>
      </Grid>
    </Layout>
  )
}

export default ThreedcartSetup
